export const useBlogData = () => {
  const { sanitize } = useUtils();
  const config = useRuntimeConfig()

  const transformBlogHome = (response) => {
    const data = {
      id: response.data.id,
      title: response.data.attributes.title,
      body: response.data.attributes.body,
      blocks: sanitize(response.data.attributes.blocks.map((element) => {
        const result = {
          ...element,
          component: element.__component,
        }
        delete result.__component;
        return result;
      })),
      noResultsAvailable: response.data.attributes.noResultsAvailable,
      jumpToTop: response.data.attributes.jumpToTop,
      seo: sanitize(response.data.attributes.seo)
    }
    return data;
  }

  const transformBlogSettings = (response) => {
    const data = {
      id: response.data.id,
      featuredPosts: response.data.attributes.featuredPosts.data,
      categories: response.data.attributes.blogCategories.data,
    }
    return data;
  }

  const transformPosts = (response) => {
    const data = response.data.map((element) => {
      let date = Date.parse(element.attributes.publishedAt)
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
      let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      return {
        id: element.id,
        author: element.attributes.author.data ? element.attributes.author.data.attributes : null,
        // blocks: element.attributes.blocks,
        // blocks_bottom: element.attributes.blocks_bottom,
        categories: element.attributes.categories.data,
        image: element.attributes.image.data && element.attributes.image.data != null ? element.attributes.image.data.attributes : element.attributes.boltImage && element.attributes.boltImage != undefined ? element.attributes.boltImage : null,
        publishedAt: `${month} ${day}, ${year}`,
        publishedAtRaw: element.attributes.publishedAt,
        // relatedPosts: element.attributes.relatedPosts.data,
        shortDescription: element.attributes.shortDescription,
        slug: element.attributes.slug,
        title: element.attributes.title,
        // updatedAt: element.attributes.updatedAt,
      }
    })
    return {
      data: data,
      meta: response.meta
    }
  }

  const transformPost = (response) => {
    
    let date = Date.parse(response.data.attributes.publishedAt)
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    let publicationDate = Date.parse(`${response.data.attributes.publicationDate}T00:00:00`);
    let publicationYear = !isNaN(publicationDate)
    ? new Intl.DateTimeFormat('en', { year: 'numeric' }).format(publicationDate)
    : null;
    let publicationMonth = !isNaN(publicationDate)
      ? new Intl.DateTimeFormat('en', { month: 'long' }).format(publicationDate)
      : null;
    let publicationDay = !isNaN(publicationDate)
      ? new Intl.DateTimeFormat('en', { day: '2-digit' }).format(publicationDate)
      : null;
    
    const data = {
      ...response.data.attributes,
      id: response.data.id,      
      title: response.data.attributes.title,
      image: response.data.attributes.image !== null && response.data.attributes.image.data !== null? response.data.attributes.image.data.attributes : null,
      shortDescription: response.data.attributes.shortDescription,
      author: response.data.attributes.author.data ? response.data.attributes.author.data.attributes : null,
      categories: response.data.attributes.categories,
      relatedPosts: response.data.attributes.relatedPosts.data,
      publishedAt: `${month} ${day}, ${year}`,
      publishedAtRaw: response.data.attributes.publishedAt,
      publicationDate: publicationYear && publicationMonth && publicationDay
      ? `${publicationMonth} ${publicationDay}, ${publicationYear}`
      : null,
      blocks: sanitize(response.data.attributes.blocks.map((element) => {
        const result = {
          ...element,
          component: element.__component,
        }
        delete result.__component;
        return result;
      })),
      blocks_bottom: sanitize(response.data.attributes.blocks_bottom.map((element) => {
        const result = {
          ...element,
          component: element.__component,
        }
        delete result.__component;
        return result;
      })),
      
    }
    return data;
  }

  const transformAuthor = (response) => {
    let author = response.data[0].attributes
    const data = {
      id: response.data[0].id,
      name: author.name,
      image: author.image.data.attributes,
      background: author.background,
      linkedIn: author.linkedInUrl,
      slug: author.slug
    }
    return data;
  }

  const transformCategories = (response) => {
    const data = response.data.map((element) => {
      return {
        id: element.id,
        category: element.category,
        slug: element.slug
      }
    })
    return {
      data: data,
      meta: response.meta
    }
  }

  const transformCategory = (response) => {
    let category = response.data[0].attributes
    const data = {
      id: response.data[0].id,
      category: category.category,
      slug: category.slug
    }
    return data;
  }

  const transformLeadGen = (response) => {
    const result = {
      ...response.data.attributes
    }
    return result;
  }

  const getBlogHome = async () => {
    const url = `/api/blog`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transformBlogHome
      });
    }
    
    return await useFetch(url, {
      method: 'GET',
      transform: transformBlogHome
    });
  }

  const getBlogSettings = async () => {
    const url = `/api/blog-setting`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transformBlogSettings
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformBlogSettings
    });
    
  }

  const getPosts = async (paramsString = '?sort=publishedAt:DESC&_start=0') => {
    const url = `/api/posts${paramsString}`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transformPosts
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformPosts
    });
    
  }

  const getPostBySlug = async (slug) => {
    const { findOneBySlug } = useStrapiAsyncData();
    const url = 'posts';
    const params = {
      slug: slug,
      entity: url
    }
    return findOneBySlug(url, params, transformPost);
  }

  const getAuthorBySlug = async (slug) => {
    const url = `/api/blog-author?slug=${slug}`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transformAuthor
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformAuthor
    });
    
  }

  const getPrevPost = async (date) => {
    const url = `/api/posts-prev?date=${date}`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transformPosts
      })
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformPosts
    })
    
  }

  const getCategoryBySlug = async (slug) => {
    const url = `/api/blog-categories?slug=${slug}`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transformCategory
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformCategory
    });
    
  }

  const getBlogCategories = async () => {
    const url = `/api/blog-category?sort=publishedAt:DESC&_start=0`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transformCategories
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformCategories
    });
    
  }

  const getNextPost = async (date) => {
    const url = `/api/posts-next?date=${date}`;

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transformPosts
      })
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transformPosts
    })
    
  }

  const blogLeadGen = async (deep = true) => {
    const url = `/api/blog-floating-block-lead-gen`

    if (config.public.nuxtEnvironment === 'production') {
      let res = await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,
        transform: transformLeadGen
      })
      return res.data
    }

    let res = await useFetch(url, {
      method: 'GET',
      transform: transformLeadGen
    })
    return res.data
    
  }

  return {
    getBlogSettings,
    getBlogHome,
    getPosts,
    getPostBySlug,
    getAuthorBySlug,
    getPrevPost,
    getNextPost,
    getBlogCategories,
    getCategoryBySlug,
    blogLeadGen
  }
}
